import { VISSION_LOADER, SET_VISSION_DATA } from "../actionTypes";

const initialState = {
  loader: false,
  vissionData: {},
};

const vissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case VISSION_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_VISSION_DATA: {
      return {
        ...state,
        vissionData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default vissionReducer;
