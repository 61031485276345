// src/validation/validationSchema.js
import * as yup from "yup";

// Common email and password schema
const emailSchema = yup
  .string()
  .transform((value) => value?.toLowerCase() || "")
  .required("Email is required")
  .matches(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "Please enter a valid email.",
  );

// Common PhoneNumber  schema
const phoneNumberSchema = yup
  .string()
  .matches(/^\d{10,11}$/, "Phone number must be 10 or 11 digits")
  .required("Phone number is required");

// Common password schema
const passwordSchema = yup
  .string()
  .required("Password is required")
  .min(8, "Password must be at least 8 characters")
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+\[\]{}\\|;':",./<>?`~\-])[A-Za-z\d!@#$%^&*()_+\[\]{}\\|;':",./<>?`~\-]{8,}$/,
    "Password must contain at least 8 characters, one letter, one number, and one special character",
  );

// Common validation schema for profile image
const profileImageSchema = yup
  .mixed()
  .required("Profile picture is required")
  .test("fileType", "Unsupported File Format", (value) => {
    return (
      value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
    );
  });
// Common validation schema for name
const nameSchema = yup
  .string()
  .required("Name is required")
  .min(3, "Name is too short, minimum 3 characters")
  .max(30, "Name is too long")
  .matches(/^[a-zA-Z ]*$/, "Name should only contain alphabets");

// Common validation schema for date
const dateSchema = yup.date().required("Date is Required");

// reCaptcha validation schema
const reCaptchaSchema = yup.string().required("ReCaptcha field is required");

// Define userType-specific schemas
const userTypeDependentSchemas = {
  generalPublic: yup.object().shape({
    fullName: yup.string().required("Full Name is required"),
  }),
  governmentEmployee: yup.object().shape({
    governmentID: yup.string().required("Government ID is required"),
  }),
  student: yup.object().shape({
    studentID: yup.string().required("Student ID is required"),
  }),
  privateSectorWithMembership: yup.object().shape({
    businessLicense: yup.string().required("Business license is required"),
    membershipNumber: yup.string().required("Membership number is required"),
  }),
  privateSectorWithoutMembership: yup.object().shape({
    businessLicense: yup.string().required("Business license is required"),
  }),
  arbitrator: yup.object().shape({
    arbitratorCategory: yup
      .string()
      .required("Arbitrator Category is required"),
    certifications: yup
      .array()
      .of(yup.mixed().required("Certification file is required"))
      .min(1, "At least one certification is required")
      .required("Certifications are required"),
    experience: yup.string().required("Experience is required"),
  }),
  expert: yup.object().shape({
    expertiseField: yup.string().required("Expertise field is required"),
  }),
  translator: yup.object().shape({
    languages: yup
      .array()
      .of(yup.string().required("Language is required"))
      .min(1, "At least one language is required")
      .required("Languages are required"),
    certifications: yup
      .array()
      .of(yup.mixed().required("Certification file is required"))
      .min(1, "At least one certification is required")
      .required("Certifications are required"),
    experience: yup.string().required("Experience is required"),
    translationRate: yup
      .number()
      .positive()
      .required("Translation rate is required"),
  }),
  systemAdministrator: yup.object().shape({
    adminID: yup.string().required("Administrator ID is required"),
  }),

  centerAdministrator: yup.object().shape({
    adminID: yup.string().required("Center Administrator ID is required"),
  }),

  centerEmployee: yup.object().shape({
    employeeID: yup.string().required("Employee ID is required"),
  }),
};

export const registerSchema = yup
  .object()
  .shape({
    userName: nameSchema,
    email: emailSchema,
    password: passwordSchema,
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords do not match")
      .required("Confirm Password is required"),
    profileImage: profileImageSchema,
    arbitratorCheck: yup.boolean(),
    userType: yup
      .string()
      .oneOf(Object.keys(userTypeDependentSchemas), "Invalid user type")
      .required("User type is required"),
    reCaptcha: reCaptchaSchema,
  })
  .test(
    "userType-dependent-validation",
    "Invalid user type details",
    function (values, context) {
      const { userType, ...rest } = values;

      if (userType && userTypeDependentSchemas[userType]) {
        try {
          userTypeDependentSchemas[userType].validateSync(rest, {
            context: this.options.context,
            abortEarly: false,
          });
          return true;
        } catch (err) {
          if (err.inner && err.inner.length) {
            const errors = err.inner.map((validationError) =>
              this.createError({
                path: validationError.path,
                message: validationError.message,
              }),
            );
            throw new yup.ValidationError(errors);
          }
        }
      }
      return true;
    },
  );
// Schema for profile update
export const profileSchema = yup.object().shape({
  username: nameSchema,
  profileImage: profileImageSchema,
});

// Schema for login or authentication
export const loginSchema = yup.object().shape({
  email: emailSchema,
  password: passwordSchema,
  reCaptcha: reCaptchaSchema,
});

// Schema for forgot password
export const forgotPasswordSchema = yup.object().shape({
  email: emailSchema,
});
// Schema for change password
export const changePasswordSchema = yup.object().shape({
  password: passwordSchema,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords do not match")
    .required("Confirm Password is required"),
});

// Schema for resend  email
export const resendEmailSchema = yup.object().shape({
  email: emailSchema,
});

// Schema for FAQ
export const faqSchema = yup.object().shape({
  name: nameSchema,
  email: emailSchema,
  subject: yup.string().required("Subject is required"),
  message: yup.string().required("Message is required"),
});

// Define the schema for an arbitration claimant
export const arbitrationSchema = yup.object().shape({
  arbitrationClaimant: yup
    .array()
    .of(
      yup.object().shape({
        name: nameSchema,
        address: yup.string().required("Address is required"),
        workTelephone: yup.string().nullable(), // Not required
        fax: yup.string().nullable(), // Not required
        lawyer: yup.string().nullable(), // Not required
        lawyerAddress: yup.string().required("Lawyer Address is required"),
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
      }),
    )
    .required("At least one claimant is required"),

  arbitrationRespondent: yup
    .array()
    .of(
      yup.object().shape({
        name: nameSchema,
        address: yup.string().required("Address is required"),
        workTelephone: yup.string().nullable(), // Not required
        fax: yup.string().nullable(), // Not required
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
      }),
    )
    .required("At least one respondent is required"),

  subject: yup.string().required("Subject is required"),

  claims: yup.string().required("Claims are required"),

  documents: yup.array().of(yup.mixed().required("A file is required")),
});

// Define the schema for an Intervention claimant
export const interventionSchema = yup.object().shape({
  interventionClaimant: yup
    .array()
    .of(
      yup.object().shape({
        name: nameSchema,
        address: yup.string().required("Address is required"),
        workTelephone: yup.string().nullable(), // Not required
        fax: yup.string().nullable(), // Not required
        lawyer: yup.string().nullable(), // Not required
        lawyerAddress: yup.string().required("Lawyer Address is required"),
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
      }),
    )
    .required("At least one claimant is required"),

  interventionRespondent: yup
    .array()
    .of(
      yup.object().shape({
        name: nameSchema,
        address: yup.string().required("Address is required"),
        workTelephone: yup.string().nullable(), // Not required
        fax: yup.string().nullable(), // Not required
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
      }),
    )
    .required("At least one respondent is required"),

  subject: yup.string().required("Subject is required"),

  claims: yup.string().required("Claims are required"),

  documents: yup.array().of(yup.mixed().required("A file is required")),
});

// Define the schema for an Joinder claimant
export const joinderSchema = yup.object().shape({
  joinderClaimant: yup
    .array()
    .of(
      yup.object().shape({
        name: nameSchema,
        address: yup.string().required("Address is required"),
        workTelephone: yup.string().nullable(), // Not required
        fax: yup.string().nullable(), // Not required
        lawyer: yup.string().nullable(), // Not required
        lawyerAddress: yup.string().required("Lawyer Address is required"),
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
      }),
    )
    .required("At least one claimant is required"),

  joinderRespondent: yup
    .array()
    .of(
      yup.object().shape({
        name: nameSchema,
        address: yup.string().required("Address is required"),
        workTelephone: yup.string().nullable(), // Not required
        fax: yup.string().nullable(), // Not required
        phoneNumber: phoneNumberSchema,
        email: emailSchema,
      }),
    )
    .required("At least one respondent is required"),

  subject: yup.string().required("Subject is required"),

  claims: yup.string().required("Claims are required"),

  documents: yup.array().of(yup.mixed().required("A file is required")),
});

export const certifiedCopySchema = yup.object().shape({
  caseDate: yup.string().required("Date is Required"),
  caseNo: yup.string().required("Case No. is Required"),
  legalRepresentative: yup
    .string()
    .required("Legal Representative is Required")
    .matches(/^[A-Za-z\s]+$/, "Invalid Name"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  copies: yup
    .string()
    .required("No of copies is Required")
    .matches(/^\d+$/, "Phone number must contain only numbers"),
  signatureDate: yup
    .date()
    .required("Date of issue is Required")
    .min(new Date(), "Signature date must be today or in the future"),
  signature: yup.string().required("Signature is Required"),
});

export const interpretationSchema = yup.object().shape({
  caseDate: yup.date().required("Date is required"),
  caseNo: yup.string().required("Case No. is required"),
  legalRepresentative: yup
    .string()
    .required("Legal Representative is required")
    .matches(/^[a-zA-Z\s]*$/, "Only letters and spaces are allowed"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  awardDateOfIssuance: yup
    .date()
    .required("Award Date of Issuance is required"),
  awardDateOfReceipt: yup.date().required("Award Date of Receipt is required"),
  interpretationSubject: yup
    .string()
    .required("Interpretation Subject is required"),
  signatureDate: yup.date().required("Signature Date is required"),
  signature: yup.string().required("Signature is required"),
  documents: yup.mixed().required("A file is required"),
  reCaptcha: reCaptchaSchema,
});

// Define contact-us validation schema
export const contactSchema = yup.object().shape({
  name: nameSchema,
  email: emailSchema,
  subject: yup.string().required("Subject is required"),
  message: yup
    .string()
    .required("Message is required")
    .min(10, "Message must be at least 10 characters"),
  reCaptcha: reCaptchaSchema,
});

// Define claim validation schema
export const claimSchema = yup.object().shape({
  caseDate: dateSchema,
  caseNo: yup.string().required("Case No. is required"),
  legalRepresentative: yup
    .string()
    .required("Legal Representative is required")
    .matches(/^[a-zA-Z ]*$/, "Only alphabets are allowed"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  copies: yup
    .number()
    .required("Number of copies is required")
    .min(1, "At least one copy is required"),
  signatureDate: yup.date().required("Signature Date is required"),
  signature: yup.string().required("Signature is required"),
  reCaptcha: reCaptchaSchema,
});

// Define objection validation schema
export const objectionSchema = yup.object().shape({
  caseDate: dateSchema,
  caseNo: yup.string().required("Case No. is required"),
  legalRepresentative: yup
    .string()
    .required("Legal Representative is required")
    .matches(/^[a-zA-Z ]*$/, "Only alphabets are allowed"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  challenge: yup.string().required("Challenge is required"),
  signatureDate: yup.date().required("Signature Date is required"),
  signature: yup.string().required("Signature is required"),
});

// Define certificate  validation schema
export const certificateSchema = yup.object().shape({
  caseDate: dateSchema,
  RequestedBy: yup.string().required("Requested by is Required"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  AddressedTo: yup.string().required("Addressed To field is Required"),
  otherDetails: yup.string().required("Further details is Required"),
  signatureDate: yup.date().required("Date of issue is Required"),
  signature: yup.string().required("Signature is Required"),
  selectedSubject: yup
    .string()
    .required("Please select a subject of the certificate"),
});

// Define validation schema
export const nominationSchema = yup.object({
  caseDate: dateSchema,
  caseNo: yup.string().required("Case number is required"),
  RequestedBy: yup.string().required("Requested by is required"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  nameOfArbitrator: nameSchema,
  nationality: yup.object().required("Nationality is required"),
  specialization: yup.string().required("Specialization is required"),
  addressAritrator: yup.string().required("Address is required"),
  emailArbitrator: emailSchema,
  phoneNumberArbitrator: phoneNumberSchema,
  challenge: yup.string().required("Challenge is required"),
  signatureDate: yup.date().required("Signature date is required"),
  signature: yup.string().required("Signature is required"),
});

// Define not governed validation schema
export const nonGovernedSchema = yup.object().shape({
  caseDate: dateSchema,
  caseNo: yup.string().required("Case No. is required"),
  legalRepresentative: yup
    .string()
    .required("Legal Representative is required")
    .matches(/^[a-zA-Z ]*$/, "Only alphabets are allowed"),
  email: emailSchema,
  phoneNumber: phoneNumberSchema,
  applicableLaw: yup.string().required("applicable law is required"),
  signatureDate: yup.date().required("Signature Date is required"),
  signature: yup.string().required("Signature is required"),
});
