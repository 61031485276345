import {
  SET_ABOUT_US_DATA,
  ABOUT_US_LOADER,
  SET_ABOUT_US_HOME_DATA,
} from "../actionTypes";

const initialState = {
  loader: false,
  aboutUsData: {},
  aboutUsHomeData: {},
};

const aboutUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ABOUT_US_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_ABOUT_US_DATA: {
      return {
        ...state,
        aboutUsData: action.payload,
      };
    }
    case SET_ABOUT_US_HOME_DATA: {
      return {
        ...state,
        aboutUsHomeData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default aboutUsReducer;
