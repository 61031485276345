import {
  SERVICE_LOADER,
  SET_SERVICES_DATA,
  SET_SINGLE_SERVICE_DATA,
} from "../actionTypes";

const initialState = {
  loader: false,
  servicesData: {},
  singleServiceData: {},
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_SERVICES_DATA: {
      return {
        ...state,
        servicesData: action.payload,
      };
    }
    case SET_SINGLE_SERVICE_DATA: {
      return {
        ...state,
        singleServiceData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default servicesReducer;
