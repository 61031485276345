import React, { useEffect, useState } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./custom.css";
import Routes from "./Routes";
import { useTranslation } from "react-i18next";
import FullPageLoader from "./components/FullPageLoader/FullPageLoader";

const loadBootstrapCSS = (isRTL, setIsLoading) => {
  const existingLinkLTR = document.getElementById("bootstrap-ltr");
  const existingLinkRTL = document.getElementById("bootstrap-rtl");

  const handleCSSLoad = () => {
    setIsLoading(false);
  };

  setIsLoading(true);

  if (isRTL) {
    if (!existingLinkRTL) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href =
        "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.rtl.min.css";
      link.id = "bootstrap-rtl";
      link.onload = handleCSSLoad;

      // Insert the link at the top of the head
      document.head.insertBefore(link, document.head.firstChild);

      document.body.classList.add("rtl");
      document.body.classList.remove("ltr");
    }
    if (existingLinkLTR) {
      existingLinkLTR.remove();
    }
  } else {
    if (!existingLinkLTR) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href =
        "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css";
      link.id = "bootstrap-ltr";
      link.onload = handleCSSLoad;

      // Insert the link at the top of the head
      document.head.insertBefore(link, document.head.firstChild);

      document.body.classList.add("ltr");
      document.body.classList.remove("rtl");
    }
    if (existingLinkRTL) {
      existingLinkRTL.remove();
    }
  }
  // import("./custom.css")
};

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const { i18n } = useTranslation();
  useEffect(() => {
    const direction = i18n.dir();
    loadBootstrapCSS(direction === "rtl", setIsLoading);
    document.documentElement.setAttribute("dir", direction);
  }, [i18n.language]);

  return <div>{isLoading ? <FullPageLoader /> : <Routes />}</div>;
}

export default App;
