import { CENTER_TABLE_LOADER, SET_CENTER_TABLE_DATA } from "../actionTypes";

const initialState = {
  loader: false,
  centerTableData: {},
};

const centerTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CENTER_TABLE_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_CENTER_TABLE_DATA: {
      return {
        ...state,
        centerTableData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default centerTablesReducer;
