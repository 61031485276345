import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Images } from "../../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLocationDot,
  faMoon,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "./footer.css";
import { useDispatch, useSelector } from "react-redux";
import { getSiteSettingData } from "../../../redux/site-settings/action";

function Footer({
  increaseFontSize,
  decreaseFontSize,
  theme,
  toggleTheme,
  toggleColorlessMode,
}) {
  const dispatch = useDispatch();
  const siteSettings = useSelector((state) => state.siteSettings);
  useEffect(() => {
    if (!siteSettings?.siteSettingsData.length > 0)
      dispatch(getSiteSettingData());
  }, []);

  return (
    <section className="footer text-white position-relative">
      <div className="top-sec">
        <Container className="custom-container">
          <Row>
            <Col lg={4}>
              <div className="logo-content-wrapper">
                <div className="mb-xl-5 mb-4">
                  <strong className="d-inline-block mb-lg-4 mb-3">
                    <Link to="#" className="d-inline-block">
                      <Image src={Images.footerLogo} alt="logo" fluid />
                    </Link>
                  </strong>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.
                  </p>
                </div>
                <div className="social-contact">
                  <h4 className="mb-3">Social Contact</h4>
                  <ul className="social-icons list-unstyled mb-0 d-flex gap-3">
                    <li>
                      <a
                        href={
                          siteSettings?.siteSettingsData?.find(
                            (item) => item.attributeName === "facebook",
                          )?.attributeValue || "#"
                        }
                        className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          siteSettings?.siteSettingsData.find(
                            (item) => item.attributeName === "youtube",
                          )?.attributeValue || "#"
                        }
                        className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          siteSettings?.siteSettingsData.find(
                            (item) => item.attributeName === "instagram",
                          )?.attributeValue || "#"
                        }
                        className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          siteSettings?.siteSettingsData.find(
                            (item) => item.attributeName === "twitter",
                          )?.attributeValue || "#"
                        }
                        className="text-white text-decoration-none d-inline-flex align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={3} md={5} sm={7}>
              <div className="content-wrapper">
                <h4 className="title">Services</h4>
                <ul className="footer-links list-unstyled mb-0">
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      Settlement of Commercial
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      Legal Seminar Or Workshop
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      Training Courses For Arbitrators
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      Legal Information
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      Arbitration fees e-Calculator
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={2} md={3} sm={5}>
              <div className="content-wrapper">
                <h4 className="title">Useful Links</h4>
                <ul className="footer-links list-unstyled mb-0">
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      e-Services
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="about-us" className="text-decoration-none">
                      About us
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="#" className="text-decoration-none">
                      Services
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="/mediation" className="text-decoration-none">
                      Mediation
                    </Link>
                  </li>
                  <li className="mb-xl-4 mb-md-3 mb-2">
                    <Link to="/arbitration" className="text-decoration-none">
                      Arbitration
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={4}>
              <div className="content-wrapper">
                <h4 className="title">Contact us</h4>
                <ul className="contact-info list-unstyled mb-4">
                  <li className="mb-md-3 mb-2">
                    <a
                      href={`tel::${
                        siteSettings?.siteSettingsData.find(
                          (item) => item.attributeName === "phone",
                        )?.attributeValue || "#"
                      }`}
                      className="d-inline-flex align-items-center gap-3 text-decoration-none"
                    >
                      <FontAwesomeIcon
                        icon={faPhone}
                        width={16}
                        className="flex-shrink-0"
                      />
                      <span>
                        {siteSettings?.siteSettingsData.find(
                          (item) => item.attributeName === "phone",
                        )?.attributeValue || "#"}
                      </span>
                    </a>
                  </li>
                  <li className="mb-md-3 mb-2">
                    <a
                      href={`mailto::${
                        siteSettings?.siteSettingsData.find(
                          (item) => item.attributeName === "email",
                        )?.attributeValue || "#"
                      }`}
                      className="d-inline-flex align-items-center gap-3 text-decoration-none"
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        width={16}
                        className="flex-shrink-0"
                      />
                      <span>
                        {siteSettings?.siteSettingsData.find(
                          (item) => item.attributeName === "email",
                        )?.attributeValue || "#"}
                      </span>
                    </a>
                  </li>
                  <li className="mb-md-3 mb-2">
                    <Link
                      href="#"
                      className="d-inline-flex align-items-center gap-3 text-decoration-none"
                    >
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        width={16}
                        className="flex-shrink-0"
                      />
                      <span>
                        {siteSettings?.siteSettingsData.find(
                          (item) => item.attributeName === "location",
                        )?.attributeValue || "#"}
                      </span>
                    </Link>
                  </li>
                </ul>
                <ul className="bottom-icons-list list-unstyled d-flex gap-2">
                  <li
                    role="button"
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => increaseFontSize()}
                  >
                    A+
                  </li>
                  <li
                    role="button"
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => decreaseFontSize()}
                  >
                    A-
                  </li>
                  <li
                    role="button"
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => toggleTheme()}
                    // variant={darkMode ? "light" : "dark"}
                  >
                    <FontAwesomeIcon className="moon-svg" icon={faMoon} />
                    <svg
                      className="sun-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="#fff"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="5"
                        stroke="transparent"
                        strokeWidth="2"
                      />
                      <path
                        d="M12 2V4"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M12 20V22"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M4 12L2 12"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M22 12L20 12"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M19.7778 4.22266L17.5558 6.25424"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M4.22217 4.22266L6.44418 6.25424"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M6.44434 17.5557L4.22211 19.7779"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M19.7778 19.7773L17.5558 17.5551"
                        stroke="inherit"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </li>
                  <li
                    role="button"
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => toggleColorlessMode()}
                  >
                    <FontAwesomeIcon className="eye-open" icon={faEye} />
                    <FontAwesomeIcon className="eye-slash" icon={faEyeSlash} />
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bottom-sec text-center text-white">
        <Container>
          <p className="mb-0">
            Copyright © 2023 Ras Al Khaimah | All Rights Reserved.
          </p>
        </Container>
      </div>
    </section>
  );
}

export default Footer;
