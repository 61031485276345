import authReducer from "./auth/reducer";
import e_servicesReducer from "./e-services/reducer";
import userReducer from "./user/reducer";
import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";
import ArbitratorReducer from "./arbitrator/e-services/reducer";
import landing_pageReducer from "./landing-page/reducer";
import juryPanelReducer from "./jury-panel/reducer";
import departmentCenterReducer from "./departmentCenter/reducer";
import lawReducer from "./laws/reducer";
import arbitrationPageReducer from "./arbitration/reducer";
import siteSettingsReducer from "./site-settings/reducer";
import servicesReducer from "./services/reducer";
import vissionReducer from "./vission/reducer";
import faqReducer from "./faq/reducer";
import centerTablesReducer from "./center-tables/reducer";
import aboutUsReducer from "./about-us/reducer";

const middleware = [thunk];
const reducer = combineReducers({
  auth: authReducer,
  e_services: e_servicesReducer,
  user: userReducer,
  Arbitrator: ArbitratorReducer,
  landingPage: landing_pageReducer,
  juryPanel: juryPanelReducer,
  departmentCenter: departmentCenterReducer,
  law: lawReducer,
  arbitration: arbitrationPageReducer,
  siteSettings: siteSettingsReducer,
  services: servicesReducer,
  vission: vissionReducer,
  faq: faqReducer,
  centerTable: centerTablesReducer,
  aboutUs: aboutUsReducer,
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
