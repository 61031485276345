import {
  SET_LAW_PAGE_DATA,
  SET_LAW_TYPES_DATA,
  LAW_LOADER,
} from "../actionTypes";

const initialState = {
  loader: false,
  lawPageData: {},
  laws: [],
};

const lawReducer = (state = initialState, action) => {
  switch (action.type) {
    case LAW_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_LAW_TYPES_DATA: {
      return {
        ...state,
        laws: action.payload,
      };
    }
    case SET_LAW_PAGE_DATA: {
      return {
        ...state,
        lawPageData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default lawReducer;
