import React, { useState } from "react";
import { Image, Nav, NavDropdown, Offcanvas } from "react-bootstrap";
import { Images } from "../../../assets";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="toggle-icon" role="button" onClick={handleShow}>
        <Image src={Images.toggleIcon} fluid />
      </div>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header
          closeButton
          className="p-lg-4 p-3 justify-content-end"
        >
          {/* <Offcanvas.Title className='text-blue-1' id="offcanvasNavbarLabel-expand-false">
                                    </Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0 px-4">
          <Nav className="ms-auto mb-4 d-lg-none">
            <Nav.Link as={Link} to="/home">
              Home
            </Nav.Link>
            <NavDropdown title="e-Services" className="e-services">
              <NavDropdown.Item as={Link} to="/membership">
                Memberships
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#action4">
                Arbitration Request Form
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/dashboard/e-services/request/claim"
              >
                Claim Statement Form
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/dashboard/e-services/request/interpretation">
                                                Request To Correct/Interpret The Arbitration Award</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="#action4">
                Request The Arbitrator’s Response
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/dashboard/e-services/request/intervention"
              >
                Intervention Request Form
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/dashboard/e-services/request/interpretation"
              >
                Interpretation Request Form
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/dashboard/e-services/request/joinder"
              >
                Joinder Request Form
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#action4">
                Objection Request
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/dashboard/e-services/request/copy"
              >
                Request A True Copy Of The Ruling
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#action3">
                Request A Statement/Certificate
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#action4">
                Request To Select An Arbitrator Outside The Center’s List Of
                Accredited Arbitrators
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="#action5">
                An Arbitration Request That Is Not Subject To The Centre’s Rules
                And Regulations
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="About Us">
              <NavDropdown.Item as={Link} to="/jury-panel">
                Jury Panel
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/about-laws">
                The Laws
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Services">
              <NavDropdown.Item as={Link} to="/arbitration">
                Arbitration
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/mediation">
                Mediation
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/departments-Of-Center">
                Departments Of The Center
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/center-tables">
                Center Tables
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/media-center">
              Media Center
            </Nav.Link>
            <Nav.Link as={Link} to="/contact-us">
              Contact us
            </Nav.Link>
          </Nav>
          <h4 className="text-blue-2 mb-3">Useful Link</h4>
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link as={Link} to="/Faqs">
              Frequently Asked Questions
            </Nav.Link>
            <Nav.Link as={Link} to="#action2">
              Advertisement Areas
            </Nav.Link>
            <Nav.Link as={Link} to="#action3">
              Terms And Conditions
            </Nav.Link>
            <Nav.Link as={Link} to="#action4">
              Privacy Policy
            </Nav.Link>
            <Nav.Link as={Link} to="#action5">
              Usage Policy
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;
