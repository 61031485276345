//////////////// Auth ///////////////////

export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";
export const ERR_USER = "ERR_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

////////////// Users /////////////////////

export const UPDATE_USER = "UPDATE_USER";

///////////// E-Services ////////////////

export const CREATE_ARBITRATION = "CREATE_ARBITRATION";
export const CREATE_COPY = "CREATE_COPY";
export const CREATE_CORRECTION_REQUEST = "CREATE_CORRECTION_REQUEST";

///////////// Loader ///////////////////

export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";

///////////// Arbitrator services //////////////

export const ARBITRATOR_ESERVICES_APPLICATIONS =
  "ARBITRATOR_ESERVICES_APPLICATIONS";
export const ARBITRATOR_SERVICE_COUNT = "ARBITRATOR_SERVICE_COUNT";

////////////// Landing Page //////////////////

export const SET_LANDING_PAGE_DATA = "SET_LANDING_PAGE_DATA";
export const LOADER_ACTIVE = "LOADER_ACTIVE";

////////////// News /////////////////////

export const FETCH_NEWS = "FETCH_NEWS";
export const NEWS_LOADER = "NEWS_LOADER";
export const NEWS_ERROR = "NEWS_ERROR";
export const FETCH_SINGLE_NEWS = "FETCH_SINGLE_NEWS";
export const NEWS_TOAST_HANDLER = "NEWS_TOAST_HANDLER";

///////////// Jury Panel //////////////

export const SET_JURY_PANEL_DATA = "SET_JURY_PANEL_DATA";
export const JURY_PANEL_LOADER = "JURY_PANEL_LOADER";

//////////// Department Center /////////////

export const SET_DEPT_CENTER_DATA = "SET_DEPT_CENTER_DATA";
export const DEPT_CENTER_LOADER = "DEPT_CENTER_LOADER";

//////////////// Laws ////////////////////

export const LAW_LOADER = "LAW_LOADER";
export const SET_LAW_TYPES_DATA = "SET_LAW_TYPES_DATA";
export const SET_LAW_PAGE_DATA = "SET_LAW_PAGE_DATA";
//////////// Arbitration //////////

export const ARBITRATION_PAGE_LOADER = "ARBITRATION_PAGE_LOADER";
export const SET_ARBITRATION_PAGE_DATA = "SET_ARBITRATION_PAGE_DATA";

//////////// Site Settings /////////////////

export const SITE_SETTINGS_LOADER = "SITE_SETTINGS_LOADER";
export const SET_SITE_SETTINGS = "SET_SITE_SETTINGS";

//////////////// Services ////////////////

export const SERVICE_LOADER = "SERVICE_LOADER";
export const SET_SERVICES_DATA = "SET_SERVICES_DATA";
export const SET_SINGLE_SERVICE_DATA = "SET_SINGLE_SERVICE_DATA";

/////////////// Vission /////////////////

export const VISSION_LOADER = "VISSION_LOADER";
export const SET_VISSION_DATA = "SET_VISSION_DATA";

////////////// FAQ //////////////////////

export const SET_FAQ_DATA = "SET_FAQ_DATA";
export const FAQ_FORM_LOADER_ACTIVE = "FAQ_FORM_LOADER_ACTIVE";
export const FAQ_LOADER_ACTIVE = "FAQ_LOADER_ACTIVE";
export const FAQ_FORM_SUBMITTED = "FAQ_FORM_SUBMITTED";

/////////// Center Tables ///////////
export const CENTER_TABLE_LOADER = "CENTER_TABLE_LOADER";
export const SET_CENTER_TABLE_DATA = "SET_CENTER_TABLE_DATA";

///////////// About Us ////////////////
export const ABOUT_US_LOADER = "ABOUT_US_LOADER";
export const SET_ABOUT_US_DATA = "SET_ABOUT_US_DATA";
export const SET_ABOUT_US_HOME_DATA = "SET_ABOUT_US_HOME_DATA";
