import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import {
  FAQ_FORM_LOADER_ACTIVE,
  FAQ_FORM_SUBMITTED,
  FAQ_LOADER_ACTIVE,
  SET_FAQ_DATA,
} from "../actionTypes";

export const getFaqData = () => async (dispatch) => {
  try {
    dispatch({
      type: FAQ_LOADER_ACTIVE,
      payload: true,
    });
    const res = await apiHelper("get", "/cms/get-faqs-list");
    if (res?.data) {
      dispatch({
        type: SET_FAQ_DATA,
        payload: res?.data?.data?.faqs,
      });
    }
    dispatch({
      type: FAQ_LOADER_ACTIVE,
      payload: false,
    });
    return res;
  } catch (error) {
    dispatch({
      type: FAQ_LOADER_ACTIVE,
      payload: false,
    });
    toast.error(
      error?.response?.data?.message ||
        "Something went wrong. Please try again.",
    );
  }
};
export const submitContactUsData = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: FAQ_FORM_LOADER_ACTIVE,
      payload: true,
    });

    const response = await apiHelper("post", "/contact-us/create", formData);
    if (response?.status === 200) {
      dispatch({
        type: FAQ_FORM_LOADER_ACTIVE,
        payload: false,
      });
      dispatch({
        type: FAQ_FORM_SUBMITTED,
        payload: true,
      });
      return response?.data;
    }
  } catch (error) {
    dispatch({
      type: FAQ_FORM_LOADER_ACTIVE,
      payload: false,
    });
    toast.error(
      error?.response?.data?.message ||
        "Something went wrong. Please try again.",
    );
  }
};
