import { LOADER_ACTIVE, SET_LANDING_PAGE_DATA } from "../actionTypes";

const initialState = {
  loader: false,
  landingPageData: {},
};

const landing_pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADER_ACTIVE:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_LANDING_PAGE_DATA: {
      const responses = action?.payload;
      const landingPageData = responses.reduce((acc, response) => {
        const [key] = Object.keys(response?.data?.data);
        acc[key] = response?.data?.data[key];
        return acc;
      }, {});
      return {
        ...state,
        landingPageData: landingPageData,
      };
    }
    default:
      return state;
  }
};

export default landing_pageReducer;
