import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Accordion,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { faqSchema } from "../../../validation/validationSchema";

import "./faq.css";
import { getFaqData, submitContactUsData } from "../../../redux/faq/action";

function Faq() {
  const faq = useSelector((state) => state.faq);
  const dispatch = useDispatch();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(faqSchema),
  });

  const onSubmit = async (data) => {
    const res = await dispatch(submitContactUsData(data));
    if (res?.success) {
      reset();
    }
  };

  useEffect(() => {
    dispatch(getFaqData());
  }, []);

  return (
    <section className="faq-sec">
      <Container className="custom-container">
        <Row>
          <Col lg={8}>
            <div className="content-wrapper me-xxl-5 me-lg-4">
              <h2 className="faq-text mb-lg-5 mb-4">
                Frequently Asked
                <strong className="question fw-semibold d-block">
                  Questions
                </strong>
              </h2>
              <Accordion className="faq-accordion" defaultActiveKey="0" flush>
                {faq?.faqData?.length > 0 &&
                  faq?.faqData.slice(0, 4).map((faq, index) => (
                    <Accordion.Item
                      key={faq?.id}
                      className="bg-transparent border-0"
                      eventKey={index?.toString()}
                    >
                      <Accordion.Header className="position-relative">
                        {faq?.question}
                      </Accordion.Header>
                      <Accordion.Body
                        className="bg-white faq-accordion-content"
                        dangerouslySetInnerHTML={{ __html: faq?.answer }}
                      ></Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </div>
          </Col>
          <Col lg={4}>
            <Form
              className="faq-form text-white"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h4 className="mb-md-5 mb-4 pe-xl-5">
                Still Have A Question About Our Services?
              </h4>

              {/* Name Input */}
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Your name*"
                  className="text-white bg-transparent"
                  {...register("name")}
                  isInvalid={errors?.name}
                />
                {errors?.name && (
                  <div className="invalid-feedback">
                    {errors?.name?.message}
                  </div>
                )}
              </InputGroup>

              {/* Email Input */}
              <InputGroup className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Your email*"
                  className="text-white bg-transparent"
                  {...register("email")}
                  isInvalid={errors?.email}
                />
                {errors?.email && (
                  <div className="invalid-feedback">
                    {errors?.email?.message}
                  </div>
                )}
              </InputGroup>

              {/* Subject Input */}
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Subject*"
                  className="text-white bg-transparent"
                  {...register("subject")}
                  isInvalid={errors?.subject}
                />
                {errors?.subject && (
                  <div className="invalid-feedback">
                    {errors?.subject?.message}
                  </div>
                )}
              </InputGroup>

              {/* Message Input */}
              <InputGroup className="mb-3">
                <Form.Control
                  as="textarea"
                  placeholder="Message*"
                  rows={5}
                  className="text-white bg-transparent resize-none"
                  {...register("message")}
                  isInvalid={errors?.message}
                />
                {errors?.message && (
                  <div className="invalid-feedback">
                    {errors?.message?.message}
                  </div>
                )}
              </InputGroup>

              <div className="text-end">
                <button
                  className="btn-solid-blue bg-white text-blue-2"
                  type="submit"
                  disabled={faq?.faqFormLoader}
                >
                  {faq?.formSubmitted
                    ? "Sent"
                    : faq?.faqFormLoader
                      ? "Submitting..."
                      : "Submit"}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Faq;
