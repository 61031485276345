import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/common/header/header";
import Footer from "../components/common/footer/footer";

const DefaultLayout = () => {
  const [fontSizeClassIndex, setFontSizeClassIndex] = useState(2);
  const [darkMode, setDarkMode] = useState(
    false,
    // () => { return localStorage.getItem("theme") === "dark"; }
  );
  const [isColorless, setIsColorless] = useState(
    false,
    // () => { return localStorage.getItem("colorless") === "true"; }
  );
  const [goldenTheme, setGoldenTheme] = useState(false);
  const [greenTheme, setGreenTheme] = useState(false);
  const [defaultTheme, setDefaultTheme] = useState(false);

  const fontSizeClasses = [
    "font-size-xs",
    "font-size-sm",
    "font-size-md",
    "font-size-lg",
    "font-size-xl",
  ];

  const theme = darkMode ? "dark" : "light";

  const darkTheme = () => {
    setDarkMode(true);
    // localStorage.setItem("theme", "dark");
  };

  const lightTheme = () => {
    setDarkMode(false);
    // localStorage.setItem("theme", "light");
  };

  const toggleTheme = () => {
    const newTheme = !darkMode;
    setDarkMode(newTheme);
    // localStorage.setItem("theme", newTheme ? "dark" : "light");
  };

  const toggleColorlessMode = () => {
    setIsColorless(!isColorless);
  };

  const handleGoldenTheme = () => {
    setGoldenTheme(true);
    setGreenTheme(false);
    setIsColorless(false);
  };

  const handleGreenTheme = () => {
    setGreenTheme(true);
    setGoldenTheme(false);
    setIsColorless(false);
  };

  const handlePrimaryTheme = () => {
    setDefaultTheme(true);
    setGreenTheme(false);
    setGoldenTheme(false);
    setIsColorless(false);
  };

  const handleResetSetting = () => {
    setDefaultTheme(true);
    setGreenTheme(false);
    setGoldenTheme(false);
    setIsColorless(false);
    setDarkMode(false);
    setFontSizeClassIndex(2);
  };

  const increaseFontSize = () => {
    if (fontSizeClassIndex < fontSizeClasses.length - 1) {
      setFontSizeClassIndex((prevIndex) => prevIndex + 1);
    }
  };

  const resetFontSize = () => {
    setFontSizeClassIndex(2);
  };

  const decreaseFontSize = () => {
    if (fontSizeClassIndex > 0) {
      setFontSizeClassIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    if (goldenTheme) {
      document.body.classList.add("golden-theme");
      document.body.classList.remove("green-theme");
    } else if (greenTheme) {
      document.body.classList.add("green-theme");
      document.body.classList.remove("golden-theme");
    } else if (defaultTheme) {
      document.body.classList.remove("golden-theme");
      document.body.classList.remove("green-theme");
    }
  }, [goldenTheme, greenTheme, defaultTheme]);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark-mode");
      document.body.classList.remove("light-mode");
    } else {
      document.body.classList.add("light-mode");
      document.body.classList.remove("dark-mode");
    }
  }, [darkMode]);

  useEffect(() => {
    if (isColorless) {
      document.body.classList.add("colorless-mode");
    } else {
      document.body.classList.remove("colorless-mode");
    }
    return () => {
      document.body.classList.remove("colorless-mode");
    };
  }, [isColorless]);

  useEffect(() => {
    document.body.classList.remove(...fontSizeClasses);
    document.body.classList.add(fontSizeClasses[fontSizeClassIndex]);
  }, [fontSizeClassIndex]);

  return (
    <div className="main-content-area">
      <Header
        increaseFontSize={increaseFontSize}
        resetFontSize={resetFontSize}
        decreaseFontSize={decreaseFontSize}
        theme={theme}
        darkTheme={darkTheme}
        lightTheme={lightTheme}
        toggleColorlessMode={toggleColorlessMode}
        handleGoldenTheme={handleGoldenTheme}
        handleGreenTheme={handleGreenTheme}
        handlePrimaryTheme={handlePrimaryTheme}
        handleResetSetting={handleResetSetting}
      />
      <Outlet />
      <Footer
        increaseFontSize={increaseFontSize}
        decreaseFontSize={decreaseFontSize}
        theme={theme}
        toggleTheme={toggleTheme}
        toggleColorlessMode={toggleColorlessMode}
      />
    </div>
  );
};
export default DefaultLayout;
