import React, { useState } from "react";
import { Modal, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import dayMode from "../../../assets/images/day-mode.svg";
import nightMode from "../../../assets/images/night-mode.svg";
import printIcon from "../../../assets/images/print.svg";
import "./settingModal.css";

function SettingModal({
  increaseFontSize,
  resetFontSize,
  decreaseFontSize,
  darkTheme,
  lightTheme,
  toggleColorlessMode,
  show,
  handleClose,
  handleGoldenTheme,
  handleGreenTheme,
  handlePrimaryTheme,
  handleResetSetting,
}) {
  const [selectedTheme, setSelectedTheme] = useState("default");
  const handleSettingCheck = () => {
    setSelectedTheme("default");
    handleResetSetting();
  };
  return (
    <Modal className="settings-modal" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <h4 className="modal-title mb-0">Custom Settings</h4>
      </Modal.Header>
      <Modal.Body>
        <ul className="list-unstyled">
          <li className="grey-box d-flex align-items-center justify-content-between">
            <h6 className="mb-0">Change Font Size</h6>
            <ul className="font-adjustment-list list-unstyled mb-0 d-flex gap-2">
              <li
                className="d-flex align-items-center justify-content-center"
                role="button"
                onClick={() => increaseFontSize()}
              >
                A+
              </li>
              <li
                className="d-flex align-items-center justify-content-center"
                role="button"
                onClick={() => resetFontSize()}
              >
                A
              </li>
              <li
                className="d-flex align-items-center justify-content-center"
                role="button"
                onClick={() => decreaseFontSize()}
              >
                A-
              </li>
            </ul>
          </li>
          <li className="grey-box">
            <h6 className="mb-3">Color Theme Selection</h6>
            <ul className="themes-list list-unstyled mb-0 d-flex flex-wrap">
              <li
                className="d-flex align-items-center gap-3 p-sm position-relative"
                role="button"
                onClick={() => handleGoldenTheme()}
              >
                <input
                  type="radio"
                  name="radio"
                  checked={selectedTheme === "golden"}
                  onChange={() => setSelectedTheme("golden")}
                />
                <div className="golden tick-icon-container">
                  <div className="tick-icon-circle">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="checkmark tick-icon"
                    />
                  </div>
                </div>
                {/* <div className="theme-circle golden"></div> */}
                Gold Theme
              </li>
              <li
                className="d-flex align-items-center gap-3 p-sm position-relative"
                role="button"
                onClick={() => handlePrimaryTheme()}
              >
                <input
                  type="radio"
                  name="radio"
                  checked={selectedTheme === "default"}
                  onChange={() => setSelectedTheme("default")}
                />
                <div className="tick-icon-container default">
                  <div className="tick-icon-circle">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="checkmark tick-icon"
                    />
                  </div>
                </div>
                Default Theme
              </li>
              <li
                className="d-flex align-items-center gap-3 p-sm position-relative"
                role="button"
                onClick={() => toggleColorlessMode()}
              >
                <FontAwesomeIcon
                  className="eye-open secondary-color"
                  icon={faEye}
                  width={24}
                  fontSize={18}
                />
                <FontAwesomeIcon
                  className="eye-slash secondary-color"
                  icon={faEyeSlash}
                  width={24}
                  fontSize={18}
                />
                Blind Theme
              </li>
              <li
                className="d-flex align-items-center gap-3 p-sm position-relative"
                role="button"
                onClick={() => handleGreenTheme()}
              >
                <input
                  type="radio"
                  name="radio"
                  checked={selectedTheme === "green"}
                  onChange={() => setSelectedTheme("green")}
                />
                <div className="green tick-icon-container">
                  <div className="tick-icon-circle">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="checkmark tick-icon"
                    />
                  </div>
                </div>
                {/* <div className="theme-circle green"></div> */}
                Green Theme
              </li>
            </ul>
          </li>
          <li className="grey-box">
            <ul className="themes-list list-unstyled mb-0 d-flex flex-wrap">
              <li
                className="d-flex align-items-center gap-2"
                role="button"
                onClick={() => darkTheme()}
              >
                <h6 className="mb-0">Night Mode</h6>
                <div>
                  <img
                    className="theme-mode-circle night"
                    src={nightMode}
                    alt=""
                  />
                </div>
              </li>
              <li
                className="d-flex align-items-center gap-2"
                role="button"
                onClick={() => lightTheme()}
              >
                <h6 className="mb-0">Day Mode</h6>
                <div>
                  <img className="theme-mode-circle day" src={dayMode} alt="" />
                </div>
              </li>
            </ul>
          </li>
          <li className="grey-box">
            <div
              className="d-flex align-items-center justify-content-between gap-3"
              role="button"
            >
              <h6 className="mb-0">Print Content</h6>
              <div>
                <img className="print-icon-circle" src={printIcon} alt="" />
              </div>
            </div>
          </li>
          <li className="grey-box">
            <button
              className="btn-solid-blue w-100"
              onClick={() => handleSettingCheck()}
            >
              Reset Setting
            </button>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}

export default SettingModal;
