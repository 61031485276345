import { SET_JURY_PANEL_DATA, JURY_PANEL_LOADER } from "../actionTypes";

const initialState = {
  loader: false,
  juryPanelData: {},
};

const juryPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case JURY_PANEL_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_JURY_PANEL_DATA: {
      return {
        ...state,
        juryPanelData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default juryPanelReducer;
