import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import { SITE_SETTINGS_LOADER, SET_SITE_SETTINGS } from "../actionTypes";

export const getSiteSettingData = () => async (dispatch) => {
  try {
    dispatch({
      type: SITE_SETTINGS_LOADER,
      payload: true,
    });
    const res = await apiHelper("get", "/site-setting/get-list");
    if (res?.data) {
      dispatch({
        type: SET_SITE_SETTINGS,
        payload: res?.data?.data,
      });
    }
    dispatch({
      type: SITE_SETTINGS_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    dispatch({
      type: SITE_SETTINGS_LOADER,
      payload: false,
    });
  }
};
