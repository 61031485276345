import { SET_DEPT_CENTER_DATA, DEPT_CENTER_LOADER } from "../actionTypes";

const initialState = {
  loader: false,
  departmentCenterData: {},
};

const departmentCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEPT_CENTER_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_DEPT_CENTER_DATA: {
      return {
        ...state,
        departmentCenterData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default departmentCenterReducer;
